<template>
    <v-row align-content="center" justify="center" class="fill-height">
        <v-col cols="12" class="text-center">
            <h2 class="display-4 grey--text">404</h2>
        </v-col>
        <v-col cols="12" class="text-center">
            <h2 class="headline grey--text">Not Found</h2>
        </v-col>
    </v-row>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

</style>